import AnimatedLogo from "components/Logo"
import Secondary from "components/buttons/Secondary"
import Tertiary from "components/buttons/Tertiary"
import gsap from "gsap"
import ScrollSmoother from "gsap/ScrollSmoother"
import { ReactComponent as ArrowSVG } from "images/global/arrow.svg"
import { ReactComponent as FullLogoSVG } from "images/global/logo-full.svg"
import UniversalLink from "library/Loader/UniversalLink"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import useAnimation from "library/useAnimation"
import { useResponsivePixels } from "library/viewportUtils"
import { useEffect, useRef, useState } from "react"
import {
	darkStaticBackground,
	staticBackground,
	staticBackgroundStatic,
} from "sections/home/01-Hero/Static"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"
import links from "utils/links"

import { ScrollTrigger } from "gsap/ScrollTrigger"
import { loader } from "library/Loader"
import { useContactModal } from "./ContactModal"
import { useNavConfig } from "./Providers/MenuColor"

const NUMBER_OF_ROWS = 6

export default function Header() {
	const { menuDark, paused, removeNav } = useNavConfig()

	const [open, setOpen] = useState(false)
	const wrapper = useRef<HTMLDivElement | null>(null)
	const line1Ref = useRef<SVGLineElement | null>(null)
	const line2Ref = useRef<SVGLineElement | null>(null)
	const line3Ref = useRef<SVGLineElement | null>(null)
	const menuRef = useRef<HTMLDivElement | null>(null)
	const innerOscar = useRef<HTMLDivElement | null>(null)
	const [, setContactFormOpen] = useContactModal()

	const mobileHeight = useResponsivePixels(NUMBER_OF_ROWS * 78 + 72)
	const [translateY, setTranslateY] = useState(0)

	// close on page change
	loader.useEventListener("routeChange", () => {
		setTranslateY(0)
		setOpen(false)
	})
	loader.useEventListener("scroll", () => {
		setOpen(false)
	})

	useAnimation(() => {
		let lastScroll = 0

		/**
		 * when we scroll up, move the header down by delta pixels
		 * when we scroll down, move the header up by delta pixels
		 */
		ScrollTrigger.create({
			onUpdate: () => {
				// check if an element with an id of "#bottom-content-product" is in the viewport

				const scroll = ScrollSmoother.get()?.scrollTop() ?? 0
				const delta = scroll - lastScroll
				lastScroll = scroll
				const height = wrapper.current?.offsetHeight ?? 0
				const bottomContentProduct = document.getElementById(
					"bottom-content-product",
				)

				const rect = bottomContentProduct?.getBoundingClientRect()
				const isInView =
					rect && rect?.top <= 0 && rect.bottom >= window.innerHeight
				if (scroll === 0) {
					return
				}
				if (delta > 0) {
					setTranslateY((prev) => Math.max(-height, prev - delta))
				} else if (isInView) {
					setTranslateY(-height)
				} else {
					setTranslateY((prev) => Math.min(0, prev - delta))
				}
			},
		})
	}, [])

	const timeline = useAnimation(() => {
		const tl = gsap.timeline({
			paused: true,
			duration: 0.3,
		})

		tl.fromTo(
			line1Ref.current,
			{
				attr: {
					x1: 0,
					x2: 24,
					y1: 8,
					y2: 8,
				},
			},
			{
				attr: {
					x1: 4,
					x2: 20,
					y1: 4,
					y2: 20,
				},
			},
			0,
		)

		tl.fromTo(
			line2Ref.current,
			{
				attr: {
					x1: 0,
					x2: 24,
					y1: 13,
					y2: 13,
				},
			},
			{
				attr: {
					x1: 4,
					x2: 20,
					y1: 4,
					y2: 20,
				},
			},
			0,
		)

		tl.fromTo(
			line3Ref.current,
			{
				attr: {
					x1: 0,
					x2: 24,
					y1: 18,
					y2: 18,
				},
			},
			{
				attr: {
					x1: 4,
					x2: 20,
					y1: 20,
					y2: 4,
				},
			},
			0,
		)

		tl.fromTo(
			menuRef.current,
			{
				height: 0,
				backgroundColor: menuDark ? colors.charcoal : colors.white,
			},
			{
				height: mobileHeight,
				backgroundColor: menuDark ? colors.charcoal : colors.white,
			},
			0,
		)

		return tl
	}, [mobileHeight, menuDark])

	useEffect(() => {
		if (!timeline) return
		const smoother = ScrollSmoother.get()

		if (open) {
			timeline.play()
			smoother?.paused(true)
		} else {
			timeline.reverse()
			smoother?.paused(false)
		}
	}, [timeline, open])

	return (
		<Wrapper $dark={menuDark} ref={wrapper} $yPos={translateY}>
			{!removeNav && (
				<>
					<Inner $paused={paused} $dark={menuDark}>
						<InnerInnerOscar ref={innerOscar}>
							<LogoWrapper to="/" ariaLabel="Aerodome home page">
								<Logo dark={menuDark} />
								<FullLogo />
							</LogoWrapper>
							<Links>
								<Tertiary dark={menuDark} to={links.product}>
									Product
								</Tertiary>
								<Tertiary dark={menuDark} to={links.about}>
									About
								</Tertiary>
								<Tertiary dark={menuDark} to={links.careers}>
									Careers
								</Tertiary>
								<Tertiary dark={menuDark} to={links.blog}>
									Blog
								</Tertiary>
								<Secondary
									type="button"
									onClick={() => {
										setContactFormOpen(true)
									}}
									dark={!menuDark}
								>
									Contact
								</Secondary>
							</Links>
							<MenuButton
								type="button"
								onClick={() => setOpen((val) => !val)}
								aria-label={open ? "Close menu" : "Open menu"}
							>
								<Icon viewBox="0 0 24 24">
									<Line ref={line1Ref} x1={0} x2={24} y1={8} y2={8} />
									<Line ref={line2Ref} x1={0} x2={24} y1={13} y2={13} />
									<Line ref={line3Ref} x1={0} x2={24} y1={18} y2={18} />
								</Icon>
							</MenuButton>
						</InnerInnerOscar>
					</Inner>
					<MenuOuter $open={open} />
					<Menu ref={menuRef}>
						<MenuInner>
							<MenuLink to={links.home}>
								<LinkInner>
									<Arrows>
										<Arrow />
										<Arrow />
										<Arrow />
									</Arrows>
									Home
								</LinkInner>
							</MenuLink>
							<MenuLink to={links.product}>
								<LinkInner>
									<Arrows>
										<Arrow />
										<Arrow />
										<Arrow />
									</Arrows>
									Product
								</LinkInner>
							</MenuLink>
							<MenuLink to={links.about}>
								<LinkInner>
									<Arrows>
										<Arrow />
										<Arrow />
										<Arrow />
									</Arrows>
									About
								</LinkInner>
							</MenuLink>
							<MenuLink to={links.careers}>
								<LinkInner>
									<Arrows>
										<Arrow />
										<Arrow />
										<Arrow />
									</Arrows>
									Careers
								</LinkInner>
							</MenuLink>
							<MenuLink to={links.blog}>
								<LinkInner>
									<Arrows>
										<Arrow />
										<Arrow />
										<Arrow />
									</Arrows>
									Blog
								</LinkInner>
							</MenuLink>
							<MenuLink
								type="button"
								onClick={() => {
									setContactFormOpen(true)
								}}
							>
								<LinkInner>
									<Arrows>
										<Arrow />
										<Arrow />
										<Arrow />
									</Arrows>
									Contact
								</LinkInner>
							</MenuLink>
						</MenuInner>
					</Menu>
				</>
			)}
			{removeNav && (
				<Inner $paused={paused} $dark={menuDark}>
					<NoNavInner>
						<NoNavLogoWrapper>
							<Logo dark={menuDark} />
							<FullLogo />
						</NoNavLogoWrapper>
					</NoNavInner>
				</Inner>
			)}
		</Wrapper>
	)
}

const Wrapper = styled.div<{ $dark: boolean; $yPos: number }>`
	position: fixed;
	z-index: 100;
	width: 100%;
	will-change: transform;
	transform: translate3d(0);
	translate: 0 ${({ $yPos }) => $yPos}px;
	transition: translate 0.1s ease-out;

	${({ $dark }) =>
		$dark
			? css`
					--text: ${colors.white};
				`
			: css`
					--text: ${colors.black};
				`}
`

const InnerInnerOscar = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	z-index: 3;
	width: 100%;

	${fresponsive(css`
		width: 1440px;
		height: 80px;
		padding: 0 40px;
	`)}

	${ftablet(css`
		height: 96px;
	`)}

  ${fmobile(css`
		height: 72px;
		padding: 0 16px;
	`)}
`

const NoNavInner = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 3;
	width: 100%;

	${fresponsive(css`
		width: 1440px;
		height: 80px;
		padding: 0 40px;
	`)}
`

const NoNavLogoWrapper = styled.div`
	display: flex;
	align-items: center;

	svg {
		path {
			fill: var(--text);
		}
	}

	${fresponsive(css`
		gap: 3px;
	`)}
`

const Inner = styled.header<{ $dark: boolean; $paused: boolean }>`
	${({ $dark, $paused }) =>
		$paused
			? staticBackgroundStatic
			: $dark
				? darkStaticBackground
				: staticBackground};
	display: flex;
	justify-content: center;
`

const LogoWrapper = styled(UniversalLink)`
	display: flex;
	align-items: center;

	svg {
		path {
			fill: var(--text);
		}
	}

	${fresponsive(css`
		gap: 3px;
	`)}
`

const Logo = styled(AnimatedLogo)`
	height: auto;
	overflow: hidden;

	${fresponsive(css`
		width: 44px;
	`)}

	${fmobile(css`
		width: 40px;
	`)}
`

const FullLogo = styled(FullLogoSVG)`
	height: auto;
	position: relative;

	${fresponsive(css`
		width: 138px;
		top: -3px;
	`)}

	${fmobile(css`
		display: none;
	`)}
`

const Links = styled.div`
	display: flex;
	align-items: center;

	${fresponsive(css`
		gap: 44px;
	`)}

	${fmobile(css`
		display: none;
	`)}
`

const MenuButton = styled.button`
	cursor: pointer;
	display: none;
	align-items: center;
	justify-content: center;
	border: 1px solid var(--text);
	z-index: 2;

	${fmobile(css`
		display: flex;
		height: 40px;
		width: 80px;
		border-radius: 8px;
	`)}
`

const Icon = styled.svg`
	${fmobile(css`
		width: 24px;
		height: 24px;
	`)}
`

const Line = styled.line`
	stroke: var(--text);
	stroke-width: 1px;
	stroke-linecap: round;
`

const Menu = styled.div`
	${staticBackground}
	background: var(--background);
	width: 100%;
	overflow: clip;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	display: none;

	${fmobile(css`
		border-radius: 0 0 16px 16px;
		display: block;
	`)}
`

const MenuInner = styled.div`
	position: absolute;
	width: 100%;
	display: flex;
	flex-direction: column;
	top: 0;
	left: 0;

	a {
		border-bottom: 1px solid var(--text);
	}

	button {
		border-bottom: 1px solid var(--text);

		&:last-of-type {
			border-bottom: none;
		}
	}

	${fmobile(css`
		padding: 72px 16px 0;
	`)}
`

const LinkInner = styled.div`
	position: relative;
	display: flex;
	transition: transform 0.5s;

	${fmobile(css`
		transform: translateX(-50px);
		gap: 16px;
	`)}
`

const MenuLink = styled(UniversalLink)`
	width: 100%;
	display: flex;
	align-items: center;
	${textStyles.button1}
	color: var(--text);
	overflow: hidden;

	${fmobile(css`
		height: 78px;

		&:hover {
			${LinkInner} {
				transform: translateX(0);
			}
		}
	`)}
`

const Arrows = styled.div`
	display: flex;
	align-items: center;
`

const Arrow = styled(ArrowSVG)`
	path {
		fill: ${colors.black};
	}

	${fmobile(css`
		width: 11px;
	`)}
`

const MenuOuter = styled.div<{ $open: boolean }>`
	display: none;
	position: fixed;
	pointer-events: none;
	${staticBackground};
	${({ $open }) =>
		fmobile(css`
			display: inline-block;
			transition: opacity 0.3s;
			top: 0;
			opacity: ${$open ? 1 : 0};
			width: 100%;
			height: 100lvh;
		`)}
`
